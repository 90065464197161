import React from 'react';
import {graphql, Link} from 'gatsby';
import {Avatar, Typography} from 'antd';
import Layout from '../../components/layout';
import Helmet from 'react-helmet';
import {useIntl} from 'gatsby-plugin-intl';
import styles from './blogTemplate.module.less'
import ArrowDownIcon from '../../components/icons/ArrowDownIcon'
import {Fade, Tooltip, useScrollTrigger} from '@material-ui/core';
import {renderRichText} from "gatsby-source-contentful/rich-text";
import richTextRendererOptions from "../richTextRendererOptions";
import GetInTouchBannerDesktop from '../../components/get-in-touch-banner-desktop/GetInTouchBannerDesktop';
import GetInTouchBannerMobile from '../../components/get-in-touch-banner-mobile/GetInTouchBannerMobile';

export default function Template({data}) {
    const {slug, title, excerpt, category, heroImage, description} = data.contentfulArticle;
    const articles = data.allContentfulArticle.edges.map(({node}) => node);
    const {locale, formatMessage} = useIntl();
    const {Title, Text} = Typography;
    const indexOfNextPost = articles.reduce((nextPostId, article, index) => {
        if (article.slug === slug) {
            return (index + 1 === articles.length)
                ? nextPostId
                : index + 1;
        }
        return nextPostId;
    }, 0);
    const trigger = useScrollTrigger();

    return (
        <Layout data={data}>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={excerpt} />
            </Helmet>
            <Fade in={trigger}>
                <div className={styles.bannerDesktop}>
                    <GetInTouchBannerDesktop />
                </div>
            </Fade>
            <div className={styles.substrate} style={{backgroundColor: category.color}}>
                <div className={styles.wrapper}>
                    <Tooltip title={formatMessage({ id: 'overview-back-button.tooltip'})}>
                        <Link
                            to={`/${locale}/magazine`}
                            className={styles.arrowLeft}>
                            <ArrowDownIcon />
                        </Link>
                    </Tooltip>
                    <div className={styles.categoryTitle}>
                        <Avatar
                            size={80}
                            src={category.icon.file.url}
                            className={styles.avatar}
                        />
                        <Text className={styles.subtitle}>
                            {category.title}
                        </Text>
                    </div>
                    <Tooltip title={formatMessage({ id: 'next-post-button.tooltip' })}>
                        <Link
                            to={`/${locale}/magazine/${articles[indexOfNextPost].slug}`}
                            className={styles.arrowRight}>
                            <ArrowDownIcon />
                        </Link>
                    </Tooltip>
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.imageWrapper}>
                    <img
                        src={heroImage.fixed.src}
                        alt={title}
                        className={styles.image}
                    />
                </div>
                <Title level={1}>{title}</Title>
                <div>{description && renderRichText(description, richTextRendererOptions)}</div>
                <Fade in={trigger}>
                    <div className={styles.bannerMobile}>
                        <GetInTouchBannerMobile />
                    </div>
                </Fade>
            </div>
        </Layout>
    );
};

export const pageQuery = graphql`
  query($slug: String!, $locale: String) {
    contentfulArticle(slug: {eq: $slug}, node_locale: {eq: $locale}) {
      title
      slug
      excerpt
      description { 
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            fixed(width: 600) {
              src
            }
          }
        }
      }
      heroImage {  
        fixed(width: 600, toFormat: PNG) {
          src
        }
      }
      category { 
        title
        icon { file { url } } 
        color 
      }
    }
    allContentfulArticle(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
          category { 
            color 
          }
        }
      }
    }
    allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
