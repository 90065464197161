import React from 'react';
import {Button, Card, Typography} from 'antd';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';
import styles from './GetInTouchBannerDesktop.module.less';

const GetInTouchBannerDesktop = () => {
    const {formatMessage, locale} = useIntl();
    const {Title, Text} = Typography;

    return (
        <Card bordered={false} className={styles.card}>
            <Title level={4} className={styles.title}>{formatMessage({id: 'get-in-touch.title'})}</Title>
            <Text className={styles.text}>{formatMessage({id: 'get-in-touch.text'})}</Text>
            <Link to={`/${locale}/contact`}>
                <Button type="primary" className={styles.button}>
                    {formatMessage({id: 'button.request-consultation'})}
                </Button>
            </Link>
        </Card>
    );
};

export default GetInTouchBannerDesktop;
