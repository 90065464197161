import React from 'react';
import {Button, Card, Col, Row, Typography} from 'antd';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';
import styles from './GetInTouchBannerMobile.module.less';

const GetInTouchBannerMobile = () => {
    const {formatMessage, locale} = useIntl();
    const {Title, Text} = Typography;

    return (
        <Card bordered={false} className={styles.card}>
            <Row gutter={[15, 15]}>
                <Col className={styles.flexCol}>
                    <Title level={4} className={styles.title}>{formatMessage({id: 'get-in-touch.title'})}</Title>
                    <Text className={styles.text}>{formatMessage({id: 'get-in-touch.text'})}</Text>
                </Col>
                <Col xs={12} sm={12} flex="none">
                    <Link to={`/${locale}/contact`}>
                        <Button type="primary" className={styles.button}>
                            {formatMessage({id: 'button.request-consultation'})}
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Card>
    );
};

export default GetInTouchBannerMobile;
